angular.module('kljDigitalLibraryApp')
    .service('guestUserService', ["$q", "appConfig", "$http",
        function($q, appConfig, $http) {
            this.getPrice = function(body, token) {
                var subscriptionBaseUrl = '/api/subscription';
                let deffered = $q.defer();
                $http({
                    method: 'POST',
                    url: subscriptionBaseUrl + '/getPrice',
                    params: appConfig.EMPTY_STRING,
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    data: body
                }).then(function successCallback(response) {
                    // this callback will be called asynchronously
                    // when the response is available
                    deffered.resolve(response.data);
                }, function errorCallback(error) {
                    // called asynchronously if an error occurs
                    // or server returns response with an error status.
                    deffered.reject(error);
                });
                return deffered.promise;
            }


            this.getPaymentDetails = function(subscription_id, couponCode, guest, token) {
                var subscriptionBaseUrl = '/api/subscription';
                let deffered = $q.defer();
                $http({
                    method: 'POST',
                    url: '/api/order/getPayUMoneyShaKey',
                    params: appConfig.EMPTY_STRING,
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    data: {
                        subscription: subscription_id,
                        coupon: couponCode,
                        guest: guest
                    }
                }).then(function successCallback(response) {
                    // this callback will be called asynchronously
                    // when the response is available
                    deffered.resolve(response.data);
                }, function errorCallback(error) {
                    // called asynchronously if an error occurs
                    // or server returns response with an error status.
                    deffered.reject(error);
                });
                return deffered.promise;
            }


            this.confirmOrder = function(subscription_id, txnid, accesscode, guest, token) {
                var subscriptionBaseUrl = '/api/payment';
                let deffered = $q.defer();
                $http({
                    method: 'POST',
                    url: '/api/payments/confirm/accesscodeSubscription',
                    params: appConfig.EMPTY_STRING,
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    data: {
                        subscription: subscription_id,
                        accesscode: accesscode,
                        txnid:txnid,
                        guest: guest
                    }
                }).then(function successCallback(response) {
                    // this callback will be called asynchronously
                    // when the response is available
                    deffered.resolve(response.data);
                }, function errorCallback(error) {
                    // called asynchronously if an error occurs
                    // or server returns response with an error status.
                    deffered.reject(error);
                });
                return deffered.promise;
            }


            this.get_order_intent = function(body, token) {
                
                var subscriptionBaseUrl = '/api/order';
                let deffered = $q.defer();
                $http({
                    method: 'POST',
                    url: subscriptionBaseUrl + '/createOrderIntent',
                    params: appConfig.EMPTY_STRING,
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    data: body
                }).then(function successCallback(response) {
                    // this callback will be called asynchronously
                    // when the response is available
                    deffered.resolve(response.data);
                }, function errorCallback(error) {
                    // called asynchronously if an error occurs
                    // or server returns response with an error status.
                    deffered.reject(error);
                });
                return deffered.promise;
            }

        }
    ]);
angular.module('kljDigitalLibraryApp')
    .service('guestAccountService', ["$q", "common", "appConfig",
        function($q, common, appConfig) {
            this.verifyUser = function(body) {
                var deferred = $q.defer();
                common.callApi('POST',
                    '/auth/guestLogin',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    body
                )

                .then(function(response) {
                        deferred.resolve(response);
                    })
                    .catch(function(error) {
                        deferred.reject(error);
                    })
                return deferred.promise;
            }
        }
    ]);